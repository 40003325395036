import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";
import { useSelector } from "react-redux";

function HeaderLinkMobile(props) {
  const { link, setOpen } = props;
  const pathname = useLocation().pathname;
  const { categories } = useSelector((state) => state.categoryReducer);
  const [prestationsList, setPrestationsList] = useState([]);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < categories.length; i++) {
      array.push(categories[i].url);
    }
    setPrestationsList(array);
  }, [categories]);

  return link.href !== "/manucure" ? (
    <div style={{ padding: "10px 0" }}>
      <Link
        to={link.href}
        onClick={() => setOpen(false)}
        className={
          pathname === link.href ? "header-link-active" : "header-link"
        }
      >
        {link.title}
      </Link>
    </div>
  ) : (
    <div style={{ padding: "10px 0" }}>
      <Link
        to={link.href}
        onClick={() => setOpen(false)}
        className={
          prestationsList.includes(pathname)
            ? "header-link-active"
            : "header-link"
        }
      >
        {link.title}
      </Link>
    </div>
  );
}

export default HeaderLinkMobile;
